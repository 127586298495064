// *****************
// Global Variables
// *****************

// Spaces
$base-spacing-unit: 20px;

// Transition
$global-transition: all .25s;


// *****************
// Typography
// *****************
$base-font-size: 17px;
$base-font-style: normal;
$base-font-variant: normal;
$base-font-weight: normal;
$base-font-family: 'Barlow', Arial, sans-serif;
$base-line-height: 1.6;

// Logo
$logo-font-family: 'Caveat', cursive;
$logo-font-weight: 700;
$logo-font-height: 1;


// Headings
$heading-font-weight: 700;
$heading-font-family: 'Barlow', Arial, sans-serif;

$font-size-h1:  36px;
$font-size-h2:  28px;
$font-size-h3:  24px;
$font-size-h4:  20px;
$font-size-h5:  18px;
$font-size-h6:  16px;

$heading-line-height: 1.4em;