/* Social */
.social {
  text-align: center;
  .social__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .social__item {
    display: inline-block;
    margin-left: 4px;
    &:first-child {
      margin-left: 0;
    }
  }
  .social__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 3px;
    box-shadow: 0 1px 2px -1px rgba(0,0,0,.1), 0 2px 2px -1px rgba(0,0,0,.06);
    color: $social-link-color;
    background-color: $social-link-background;
    &:hover {
      color: $white;
      background-color: $brand-color;
    }
  }

  @media only screen and (max-width: $desktop) {
    margin-bottom: 15px;
  }
}