*, *::after, *::before {
  box-sizing: border-box;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $text-color;
  background-color: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  &.is-in {
    &::after {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }
  &::after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $preloader-color;
    z-index: 15;
    transition: 1s;
  }
  input, textarea {
    border: 1px solid $gray-light;
    outline: none;
  }
  
  @media only screen and (max-width: $mobile) {
    font-size: 16px;
    line-height: 29px;
  }
}

::placeholder {
  color: $gray;
}

*::selection {
  color: $white;
  background-color: $brand-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  color: $heading-color;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

p {
  text-rendering: optimizelegibility;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

blockquote {
  position: relative;
  padding: 20px 20px 20px 50px;
  margin-bottom: 0;
  font-family: $heading-font-family;
  font-size: 22px;
  line-height: 38px;
  font-weight: 600;
  color: $heading-color;

  &:before {
    content: '\201C';
    position: absolute;
    font-family: $heading-font-family;
    top: 0.3em;
    left: -0.01em;
    font-size: 4em;
    color: $brand-color;
  }

  p {
    margin-bottom: 15px;
  }

  cite {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    color: $text-color;
    &:before {
      content: '\2014' '\00a0';
    }
  }

  @media only screen and (max-width: $mobile) {
    padding: 20px 20px 20px 35px;
    font-size: 18px;
    line-height: 28px;
  }
}

pre {
  overflow: auto;
  padding: 15px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

code[class*=language-], pre[class*=language-] {
  white-space: pre-wrap;
  word-break: break-all;
  line-height: inherit;
}

img, 
.zoom-img-wrap {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  &+em {
    display: block;
    padding: 15px;
    font-size: 14px;
    line-height: 1;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: $base-meta-color;
  }
}

a {
  text-decoration: none;
  color: $link-color;
  transition: $global-transition;
  &:hover {
    color: $brand-color;
  }
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 50px 0;
  border: 0;
  background: $base-border-color;
}

.table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
}

table {
  font-size: 12px;
  color: $heading-color;
  width: 100%;
  border-width: 1px;
  border-color: $table-border-color;
  border-collapse: collapse;
}

table th {
  padding: 10px;
  font-size: 16px;
  text-align: left;
  border: 1px solid $table-border-color;
  color: $white;
  font-weight: 600;
  background-color: $table-th-color;
}

table tr {
  background-color: $table-tr-color;
  transition: all .3s ease;
  &:nth-child(even) {
    background-color: transparent;
  }
}

table td {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid $table-border-color;
}

table tr:hover {
  color: $white;
  background-color: $brand-color;
}