// Display
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}


// Display State
.show {
  display: block;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}


// Lists Reset
.list-reset {
  @include list-reset();
}

// Clearfix
.clearfix {
  @include clearfix();
}

// Screen Reader
.screen-reader-text {
  @include screen-reader();
}