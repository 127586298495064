/* Page */
.page {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 50px 30px;
  @media only screen and (max-width: $mobile) {
    padding: 20px;
  }
}

.page-title {
  font-size: 43px;
  margin-bottom: 20px;
  text-align: center;
  @media only screen and (max-width: $tablet) {
    font-size: 38px;
  }
  @media only screen and (max-width: $tablet) {
    font-size: 28px;
  }
}

.page-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  user-select: none;
}

.page-image-box {
  position: relative;
  padding-top: 48%;
  min-height: 160px;
  margin-top: 20px;
  background-color: #f6f7f9;
  @media only screen and (max-width: $desktop) {
    padding-top: 55%;
  }
  @media only screen and (max-width: $tablet) {
    padding-top: 75%;
  }
  @media only screen and (max-width: $mobile) {
    margin-top: 15px;
  }
}

.page-head {
  margin: 30px 0;
}