img[data-action="zoom"] {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}
.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: 666;
  -webkit-transition: all 300ms;
       -o-transition: all 300ms;
          transition: all 300ms;
}
img.zoom-img {
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
}
.zoom-overlay {
  z-index: 420;
  background: $background-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  -webkit-transition:      opacity 300ms;
       -o-transition:      opacity 300ms;
          transition:      opacity 300ms;
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    background: rgba($background-color, .8);
  }
}
.zoom-overlay-open .zoom-overlay {
  opacity: 1;
}
.zoom-overlay-open,
.zoom-overlay-transitioning {
  cursor: default;
}