/* Tags Page */
.archive {
  padding: 3vw;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #f6f7f9;
}
.archive__box {
  position: relative;
  max-width: 600px;
  width: 100%;
  padding: 30px 40px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  background-color: $section-color;
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    background-color: rgba($section-color, .8);
  }
  @media only screen and (max-width: $mobile) {
    padding: 20px 10px;
  }
}

.archive-title {
  margin: 0;
  font-size: 33px;
  line-height: 1;
  @media only screen and (max-width: $mobile) {
    font-size: 25px;
  }
}

.archive-description {
  margin: 10px auto;
  font-size: 16px;
  line-height: 28px;
  color: $author-color;
  @media only screen and (max-width: $mobile) {
    margin: 5px auto 0;
    font-size: 14px;
    line-height: 22px;
  }
}

.tag-slider {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  div {
    height: 100%;
  }

  .tag-slider__item {
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}